import React from 'react';
// import { Link } from 'react-router-dom';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h5 className="page-header firstColor ">
        <b>Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna</b> to profesjonalna{' '}
        <b>opieka medyczna</b>, w renomowanych placówkach medycznych w całej
        Polsce dla Ciebie i członków Twojej rodziny.
      </h5>

      <div className="secondColor">
        <p>
          Zarówno pracownik jak i współpracownik ma możliwość ubezpieczenia{' '}
          <b>dorosłe dzieci</b> w 2 dostępnych zakresach:
        </p>
      </div>

      <ul className="listIcons ok">
        <li>Podstawowym</li>
        <li>Rozszerzonym</li>
      </ul>
      <p className="firstColor">
        Zakres pakietu dorosłe dziecko jest identyczny z zakresem pakietu
        podstawowego i rozszerzonego dla pracownika, współpracownika, byłego
        pracownika.
      </p>
      <p className="secondColor">
        Dla ubezpieczonych w ramach pakietów: dorosłe dziecko{' '}
        <b>możesz wybrać inny zakres</b> niż dla siebie tzn. jeżeli wybrałeś dla
        siebie zakres podstawowy, dla dorosłego dziecka możesz wybrać zakres
        rozszerzony.
      </p>
      <br />
      <b className="firstColor">Wiek uprawniający do objęcia ubezpieczeniem</b>
      <ul className="secondColor">
        <li>
          <b>dorosłe dziecko</b> – dziecko własne lub przysposobione pracownika,
          współpracownika, byłego pracownika lub jego partnera życiowego, które
          ukończyło 26 rok życia
        </li>
      </ul>
      <br />
      <div className="text-white p-2 sixthColor iconFromLeft moneyIcon">
        <h5 className="firstColor">
          <b>Składka za ubezpieczenie</b>
        </h5>
      </div>
      <br />
      <table className="table table-striped table-condensed secondColor">
        <tbody>
          <tr>
            <th className="text-center">Pakiet / zakres</th>
            <th className="text-center">Podstawowy</th>
            <th className="text-center">Rozszerzony</th>
          </tr>

          <tr>
            <th className="text-center">Dorosłe Dziecko</th>
            <td className="text-center">118,60 zł</td>
            <td className="text-center">282,60 zł</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p className="secondColor">
        Składka za ubezpieczenie płatna jest na numer subkonta bankowego
        wskazanego na deklaracji przystąpienia do ubezpieczenia.
      </p>
    </React.Fragment>
  );
};

export default Ogolne;
