import React from 'react';
// import { Link } from 'react-router-dom';
import notes from './../../../assets/img/brand/pzu/notes.png';
import mail from './../../../assets/img/brand/pzu/mail.png';

const Refundacja = (props) => {
  return (
    <React.Fragment>
      <div className="text-white p-2 sixthColor iconFromLeft moneyIcon mt-4">
        <h5 className="firstColor">
          <b>Refundacja świadczeń zdrowotnych w TUW PZUW</b>
        </h5>
      </div>
      <div className="secondColor mt-4 ml-5">
        <b className="firstColor">
          Możesz skorzystać ze świadczeń zdrowotnych poza siecią placówek
          medycznych PZU Zdrowie.
        </b>
        <p>
          Jeśli dane świadczenie medyczne znajduje się w zakresie ubezpieczenia
          (jest bezpłatne) możesz otrzymać zwrot 70% kwoty wskazanej na fakturze
          lub rachunku z limitem 2 000 zł na każdą uprawnioną osobę w ciągu roku
          (rok polisowy liczony od początku umowy ubezpieczeniowej do jej
          rocznicy).
        </p>

        <p>
          <b className="firstColor">
            Możesz również skorzystać z opcji zwrotu kosztów
          </b>
          , w przypadku niedotrzymania gwarantowanego terminu wizyty lub badania
          w wysokości 100 % poniesionych kosztów, po uprzednim kontakcie z
          infolinią. Jeśli podczas rozmowy z infolinią otrzymałeś sms z numerem
          zgłoszenia – wpisz go na wniosku o zwrot. Wpisanie kodu usprawni
          realizację zwrotu kosztów 100%.
        </p>

        <div class="container mt-4">
          <div class="row">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={notes} alt="notes" />
            </div>
            <div class="col-sm-11">
              Aby uzyskać zwrot poniesionych kosztów, dostarcz do TUW PZUW
              następujące dokumenty:
            </div>
          </div>
        </div>

        <ul className="ok ml-5 mt-2">
          <li>formularz zgłoszeniowy,</li>
          <li>
            oryginał imiennej faktury lub rachunku za zrealizowane świadczenie
            zdrowotne,
          </li>
          <li>
            kopię skierowania lekarskiego na usługi, na które skierowanie jest
            wymagane, np. badania USG, RTG, tomografia komputerowa.
          </li>
        </ul>

        <div class="container mt-4">
          <div class="row">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={mail} alt="mail" />
            </div>
            <div class="col-sm-11">
              Wypełniony formularz wraz ze wszystkimi załącznikami możesz nam
              dostarczyć:
            </div>
          </div>
        </div>

        <ul className="ok ml-5 mt-2">
          <li>
            za pośrednictwem strony{' '}
            <b className="firstColor">
              <a
                href="https://tvp-tuwpzuw.uspartner.pl/"
                target="_blank"
                rel="noopener noreferrer">
                tvp-tuwpzuw.uspartner.pl
              </a>
            </b>
            , w sekcji refundacje w zakładce „refundacje świadczeń 70%” albo
            „zwrot kosztów 100%”
          </li>
          <li>
            {' '}
            poprzez portal mojePZU – w sekcji „Usługi medyczne” w zakładce „Moje
            refundacje”
          </li>
          <li>
            w formie skanu na adres:{' '}
            <b className="firstColor">
              <a
                href="mailto:zwroty_refundacje@pzu.pl"
                target="_blank"
                rel="noopener noreferrer">
                zwroty_refundacje@pzu.pl
              </a>
            </b>
            .
          </li>
        </ul>
      </div>

      {/* <div className="text-center mt-5">
        <Link
          to="/36c1b229-f238-4da1-8513-3617faf28bf9"
          className="btn btn-lg btn-primary">
          Wypełnij wniosek refundacji
        </Link>
      </div> */}
    </React.Fragment>
  );
};

export default Refundacja;
