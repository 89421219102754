import React from 'react';
import { Fields } from 'redux-form';
import { Card, CardHeader, CardBody } from 'reactstrap';

const renderPanel = (props) => {
  const {
    isArrayField,
    customClass,
    components,
    parseSchema,
    parseProps,
    title,
    subtitle,
    theme
  } = props;

  const fieldName = props.name;

  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    console.log('array props? ', props);
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  const globalDisable = props.globalDisable === true;
  const disabled = formMeta.disabled.input.value === true;

  if (hidden) return null;

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <CardHeader className={`bg-${theme}`}>
        {title}
        {subtitle && (
          <div>
            <small>{subtitle}</small>
          </div>
        )}
      </CardHeader>

      <CardBody className={customClass}>
        {components
          ? components.map((subNode) =>
              parseSchema({
                ...parseProps,
                node: subNode,
                disabled: disabled || globalDisable
              })
            )
          : null}
      </CardBody>
    </Card>
  );

  // return (
  //   <React.Fragment>
  //     {!hidden && (
  //       <div className={centered}>
  //         <Button
  //           color={color}
  //           type="button"
  //           disabled={disabled || globalDisable}
  //           onClick={onClick}
  //           size={size}>
  //           {iconLeft}
  //           {label}
  //           {iconRight}
  //         </Button>
  //       </div>
  //     )}
  //   </React.Fragment>
  // );
};

class Panel extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        globalDisable={this.props.globalDisable}
        names={[
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderPanel}
      />
    );
  }
}

export default Panel;
