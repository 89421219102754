import { all, select, put, call } from 'redux-saga/effects';
import * as actions from '../actions';
import { autofill } from 'redux-form';
import cloneDeep from 'lodash-es/cloneDeep';
import ObservableSlim from 'observable-slim';
import { push, replace } from 'connected-react-router';
import { getFormSyncErrors, getFormValues, touch } from 'redux-form';
import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';

// import * as actionTypes from '../actions/actionTypes';

const getRegisteredFields = (formId) => (state) =>
  Object.keys(state.form[formId].registeredFields);

export function* clientSideActionSaga(action) {
  const { formId, sessionId, fieldName, arrayAction } = action.meta;
  const storeData = yield select((state) => state.form[formId].values);
  const engine = yield select(
    (state) => state.formEngine.sessionIds[sessionId]
  );
  let triggers = engine.triggers[fieldName];
  if (arrayAction) {
    // console.log('array field....', arrayAction);
    triggers = engine.triggers[arrayAction.subKey];
  }

  if (
    triggers &&
    Array.isArray(triggers.actions) &&
    !triggers.hasBackendOnlyAction
  ) {
    try {
      const formData = cloneDeep(storeData);

      let proxyChanges = {};
      // if (arrayAction) {
      //   console.log('array field....', arrayAction);
      //   const { subKey, rootKey, index, action } = arrayAction;
      // }

      //eslint-disable-next-line
      let data = ObservableSlim.create(formData, false, function (changes) {
        changes.forEach(
          (change) => (proxyChanges[change.currentPath] = change.newValue)
        );
      });

      //eslint-disable-next-line
      let formState = ObservableSlim.create(
        formData.formMeta,
        false,
        function (changes) {
          changes.forEach(
            (change) =>
              (proxyChanges[`formMeta.${change.currentPath}`] = change.newValue)
          );
        }
      );

      //eslint-disable-next-line
      const isServer = false;
      //eslint-disable-next-line
      const Log = (key) => {
        console.log('SCRIPT LOG', key);
      };
      //eslint-disable-next-line
      const enable = (key) => {
        if (formState[key]) {
          formState[key].disabled = false;
        } else {
          formState[key] = { disabled: false };
        }
      };
      //eslint-disable-next-line
      const disable = (key) => {
        if (formState[key]) {
          formState[key].disabled = true;
        } else {
          formState[key] = { disabled: true };
        }
      };
      //eslint-disable-next-line
      const show = (key) => {
        if (formState[key]) {
          formState[key].hidden = false;
        } else {
          formState[key] = { hidden: false };
        }
      };
      //eslint-disable-next-line
      const hide = (key) => {
        if (formState[key]) {
          formState[key].hidden = true;
        } else {
          formState[key] = { hidden: true };
        }
      };

      for (let action of triggers.actions) {
        const script = engine.actions[action.actionId].script;
        //eslint-disable-next-line
        yield eval(script);
        yield put({
          type: `FORMENGINE_ACTION: ${engine.actions[action.actionId].name}`,
          payload: { changes: proxyChanges }
        });
      }

      ObservableSlim.remove(data);
      ObservableSlim.remove(formState);

      // console.log('Changes: ', proxyChanges);
      yield all(
        Object.keys(proxyChanges).map((field) => {
          const value = proxyChanges[field];
          return put(autofill(formId, field, value));
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  }
}

export function* serverArrayChangeSaga(action) {
  // console.log(action.meta);
  const { formId, sessionId, rootKey, arrayAction } = action.meta;
  const engine = yield select(
    (state) => state.formEngine.sessionIds[sessionId]
  );
  let triggers = engine.triggers[rootKey];

  if (arrayAction && arrayAction.subKey) {
    // console.log('array field....', arrayAction);
    triggers = engine.triggers[arrayAction.subKey];
  }

  if (triggers) {
    // const newValue = yield select(
    //   (state) => state.form[formId].values[rootKey]
    // );
    const { formMeta, ...data } = yield select(getFormValues(formId));
    yield put(
      actions.serverChangeAction({
        sessionId: sessionId,
        formId: formId,
        trigger: rootKey,
        arrayAction,
        newValue: data[rootKey],
        data: data,
        updateWithServerValues: triggers.hasBackendOnlyAction,
        hideLoader: !triggers.hasBackendOnlyAction
      })
    );
  }
}

export function* serverFieldChangeSaga(action) {
  const { formId, sessionId, trigger } = action.meta;
  const engine = yield select(
    (state) => state.formEngine.sessionIds[sessionId]
  );
  const triggers = engine.triggers[trigger];

  if (triggers) {
    const { formMeta, ...data } = yield select(getFormValues(formId));
    yield put(
      actions.serverChangeAction({
        ...action.meta,
        data: data,
        updateWithServerValues: triggers.hasBackendOnlyAction,
        hideLoader: !triggers.hasBackendOnlyAction
      })
    );
  }
}

export function* updateFormWithServerValuesSaga(action) {
  if (action.meta.updateWithServerValues) {
    const data = {
      ...action.data.state,
      formMeta: { ...action.data.formState }
    };
    const errors = action.data.validationErrors
      ? { ...action.data.validationErrors }
      : {};
    const formId = action.meta.formId;
    yield put({
      type: '@@redux-form/REINITIALIZE',
      data: data,
      formId,
      errors
    });
  }
}

export function* initializeFormSaga(action) {
  const data = { ...action.meta.formData };
  const formId = action.meta.formId;
  yield put({ type: '@@redux-form/REINITIALIZE', data: data, formId });
}

export function* serverEventCompleteSaga(action) {
  // console.log('server event complete', action);

  const { eventAction, eventActionParams } = action.data;

  switch (eventAction) {
    case 'RedirectToPage':
      let path = `/${(eventActionParams.pageId || '').toLowerCase()}`;
      if (eventActionParams.tabPageId) {
        path = `/${(eventActionParams.pageId || '').toLowerCase()}/${(
          eventActionParams.tabPageId || ''
        ).toLowerCase()}`;
      }
      const redirectUrl = {
        pathname: path
      };
      if (eventActionParams.queryStringParams)
        redirectUrl.search = `?${new URLSearchParams(
          eventActionParams.queryStringParams
        ).toString()}`;
      yield put(push(redirectUrl));
      break;
    case 'Recalculation':
      yield put({
        ...action,
        type: success(actionTypes.FORMENGINE_SERVER_CHANGE)
      });
      break;
    case 'DownloadFile':
      yield put(actions.downloadFile(eventActionParams.fileId));
      break;
    case 'PrintPdf':
      yield put(actions.printPdf(eventActionParams.fileId));
      break;
    case 'OpenModal':
      if (eventActionParams.queryStringParams) {
        eventActionParams.queryString = `?${new URLSearchParams(
          eventActionParams.queryStringParams
        ).toString()}`;
      }

      yield put(actions.openModal(eventActionParams));

      break;
    case 'CloseModal':
      if (eventActionParams.refresh) {
        const location = yield select((state) => state.router.location);
        const { search, pathname } = location;
        const id = pathname.split('/').pop();
        yield put(actions.fetchDynamicPage(id, search));
      } else if (eventActionParams.redirect) {
        let path = `/${(eventActionParams.pageId || '').toLowerCase()}`;
        if (eventActionParams.tabPageId) {
          path = `/${(eventActionParams.pageId || '').toLowerCase()}/${(
            eventActionParams.tabPageId || ''
          ).toLowerCase()}`;
        }
        const redirectUrl = {
          pathname: path
        };
        if (eventActionParams.queryStringParams)
          redirectUrl.search = `?${new URLSearchParams(
            eventActionParams.queryStringParams
          ).toString()}`;
        yield put(push(redirectUrl));
      }
      yield put(actions.closeModal(eventActionParams));
      break;
    default:
      break;
  }
}

export function* formEventSaga(action) {
  // console.log('saga triggered', action);

  const { formId, validateAll } = action.meta;
  const { formMeta, ...data } = yield select(getFormValues(formId));
  const updateWithServerValues = true;
  // console.log(formId, data, action);

  if (!validateAll) {
    return yield put(
      actions.serverEventAction({
        ...action.meta,
        data,
        updateWithServerValues
      })
    );
  }

  const errors = yield select(getFormSyncErrors(formId)) || {};

  // Touch all fields
  const fields = yield select(getRegisteredFields(formId));
  yield put(touch(formId, ...fields));

  const filteredErrors = Object.keys(errors).filter((error) => {
    if (Array.isArray(errors[error])) {
      const arrayFiltered = errors[error].filter(
        (element) => element && Object.keys(element).length > 0
      );
      if (arrayFiltered.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  });

  if (errors && filteredErrors.length > 0) {
    console.log('there are errors not letting event through!');
    const fields = yield select(getRegisteredFields(formId));
    yield put(touch(formId, ...fields));
    alert('Proszę poprawić formularz');
  } else {
    yield put(
      actions.serverEventAction({
        ...action.meta,
        data,
        updateWithServerValues
      })
    );
  }
}

export function* formSubmitEventSaga(action) {
  // console.log('saga triggered', action);

  const { formId } = action.meta;
  const { formMeta, ...data } = yield select(getFormValues(formId));
  const updateWithServerValues = true;

  // if (!validateAll) {
  //   return yield put(actions.serverEventAction({ ...action.meta, data }));
  // }

  const errors = yield select(getFormSyncErrors(formId));

  // Touch all fields
  const fields = yield select(getRegisteredFields(formId));
  yield put(touch(formId, ...fields));

  const filteredErrors = Object.keys(errors).filter((error) => {
    if (Array.isArray(errors[error])) {
      const arrayFiltered = errors[error].filter(
        (element) => Object.keys(element).length > 0
      );
      if (arrayFiltered.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  });

  if (errors && filteredErrors.length > 0) {
    console.log('there are errors not letting event through!');
    const fields = yield select(getRegisteredFields(formId));
    yield put(touch(formId, ...fields));
    alert('Proszę poprawić formularz');
  } else {
    yield put(
      actions.serverSubmitActionSend({
        ...action.meta,
        data,
        updateWithServerValues
      })
    );
  }
}

export function* workflowTriggerSaga(action) {
  // console.log('saga triggered', action);

  const { formId } = action.meta;
  const { formMeta, ...data } = yield select(getFormValues(formId));
  // console.log(formId, data, action);

  return yield put(
    actions.workflowTriggerActionSend({
      ...action.meta,
      data
    })
  );
}

export function* workflowTriggerCompleteSaga(action) {
  // console.log('workflow trigger response', action);
  const { meta, data } = action;
  const { command, config, message, processInstanceId } = data;

  switch (command) {
    case 'ReloadPage':
      yield put(replace({ search: `?ProcessInstanceId=${processInstanceId}` }));
      if (meta.modalConfirmed) {
        yield put(actions.workflowReloadAction(meta.baseFormId, config));
        yield put(actions.closeModal({ id: meta.transitionId }));
      } else {
        yield put(actions.workflowReloadAction(meta.formId, config));
      }
      break;
    case 'Error':
      yield call(alert, message);
      break;
    case 'OpenModal':
      yield put(
        actions.openModal({
          id: meta.transitionId,
          type: 'workflow',
          data: { config: config, id: meta.transitionId },
          triggers: [meta.trigger],
          guid: meta.transitionId,
          baseFormId: meta.formId
        })
      );
      break;
    default:
      break;
  }
}
