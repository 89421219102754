import { put, call, delay, take, race } from 'redux-saga/effects';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';

import * as actions from '../actions/index';
import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';
import { actions as idleActions } from '../components/idle';

export function* logoutSaga() {
  yield call(removeAuthTokenFromStorage);
  yield put(actions.logoutSucceed());
  yield put(push('/login?loggedOut=true'));
}

export function* redirectPathSaga(action) {
  if (
    (action.payload.action === 'REPLACE' &&
      action.payload.location.pathname === '/login') ||
    (action.payload.location.pathname === '/login/' &&
      action.payload.location.state &&
      action.payload.location.state.referrer)
  ) {
    yield put(
      actions.setAuthRedirectPath(action.payload.location.state.referrer)
    );
  }
}

export function* authenticateUserSaga({ values, resolve, reject }) {
  yield put(actions.authStart());
  try {
    const credentials = {
      a: values.username,
      x: values.password
    };
    // Get token response from server
    const response = yield call(requestToken, credentials);
    const token = response.data;

    // Persist response to localstorage for persistence (including across tabs)
    yield call(setAuthTokenToStorage, token);
    const decodedToken = yield jwtDecode(token['access_token']);
    yield put(
      actions.authSuccess(
        token['access_token'],
        token['refresh_token'],
        decodedToken
      )
    );
    yield call(resolve);
    return {
      refresh_token: token['refresh_token'],
      access_token: token['access_token'],
      expires_in: token['expires_in'],
      decodedToken: decodedToken
    };
  } catch (error) {
    let errorMessage = JSON.stringify(error);
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = error.response.data;
      }
    }
    yield put(actions.authFail(errorMessage));
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

export function* restoreAuthenticationSaga(storageEvent = false) {
  const token = yield call(getAuthTokenFromStorage);

  if (token) {
    const decodedToken = yield jwtDecode(token['access_token']);

    const expirationDate = decodedToken.exp;
    const dateNow = yield Math.floor(Date.now() / 1000);

    if (expirationDate <= dateNow) {
      yield put(
        actions.logout({ reason: 'Token expired', expirationDate, dateNow })
      );
    } else {
      yield put(
        actions.authRestore(
          token['access_token'],
          token['refresh_token'],
          decodedToken
        )
      );
      return {
        refresh_token: token['refresh_token'],
        access_token: token['access_token'],
        expires_in: token['expires_in'],
        decodedToken: decodedToken
      };
    }
  } else if (!token && storageEvent) {
    yield put(actions.logout({ reason: 'No token in storage' }));
  }
}

export function* authFlowSaga(action) {
  let token = yield call(restoreAuthenticationSaga);

  while (true) {
    yield put(idleActions.stop());

    if (!token) {
      while (!token) {
        const { login, storageEvent } = yield race({
          login: take(actionTypes.AUTH_USER),
          storageEvent: take(actionTypes.AUTH_STORAGE_CHANGE)
        });

        if (login) {
          token = yield call(authenticateUserSaga, login.payload);
        } else if (storageEvent) {
          token = yield call(restoreAuthenticationSaga, storageEvent);
        }
      }
    }
    yield put(actions.fetchMenu());
    yield put(actions.getAllowedPrograms());
    yield put(idleActions.start());

    let userSignedOut;
    while (!userSignedOut) {
      // Randomize so that multiple tabs dont request refresh at the same time
      const dateNow = yield Math.floor(Date.now() / 1000);
      const randomize = yield Math.floor(Math.random() * 50);
      const tokenExpiry =
        (token.decodedToken.exp - dateNow - randomize - 10) * 1000;

      const { expired, programChange, passwordChange, storageEvent } =
        yield race({
          expired: delay(tokenExpiry),
          programChange: take(success(actionTypes.SET_PROGRAM)),
          passwordChange: take(success(actionTypes.CHANGE_PASSWORD)),
          storageEvent: take(actionTypes.AUTH_STORAGE_CHANGE),
          // expired: delay(10000),
          signout: take(actionTypes.AUTH_INITIATE_LOGOUT)
        });

      // token expired first
      if (expired) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
      } else if (programChange) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
        yield put(actions.fetchMenu());
        yield put(push('/'));
        // yield put(actions.getAllowedPrograms());
      } else if (passwordChange) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
      } else if (storageEvent) {
        token = yield call(restoreAuthenticationSaga, storageEvent);
        if (!token) {
          userSignedOut = true; // breaks the loop
        } else {
          yield put(actions.fetchMenu());
          yield put(actions.getAllowedPrograms());
        }
      }
      // user signed out before token expiration
      else {
        token = null;
        userSignedOut = true; // breaks the loop
        yield put(actions.authSagaRestart({ reason: 'User signed out' }));
      }
    }
  }
}

function* refreshToken(tokenObj) {
  const expirationDate = tokenObj.decodedToken.exp;
  const dateNow = yield Math.floor(Date.now() / 1000);

  if (expirationDate <= dateNow) {
    yield put(
      actions.logout({ reason: 'Token expired', expirationDate, dateNow })
    );
  } else {
    try {
      const response = yield call(refreshTokenRequest, tokenObj);
      const token = response.data;

      yield call(setAuthTokenToStorage, token);

      const decodedToken = yield jwtDecode(token['access_token']);

      yield put(
        actions.authRefresh(
          token['access_token'],
          token['refresh_token'],
          decodedToken
        )
      );
      return {
        refresh_token: token['refresh_token'],
        access_token: token['access_token'],
        expires_in: token['expires_in'],
        decodedToken: decodedToken
      };
    } catch (error) {
      yield put(actions.authFail(error.response.data));
      yield put(actions.logout({ reason: 'Error', error }));
      return null;
    }
  }
}

const requestToken = (credentials) => {
  return axios.post('/api/identity/login', credentials);
};

const refreshTokenRequest = (token) => {
  return axios({
    method: 'post', //you can set what request you want to be
    url: `/api/identity/refresh-tokens/${token['refresh_token']}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token['access_token']}`
    }
  });
};

const getAuthTokenFromStorage = () => {
  return JSON.parse(sessionStorage.getItem('authToken'));
};

const setAuthTokenToStorage = (token) => {
  sessionStorage.setItem('authToken', JSON.stringify(token));
};

const removeAuthTokenFromStorage = () => {
  sessionStorage.removeItem('authToken');
};

// function* authorizeLoop(token) {
//   while (true) {
//     const refresh = token != null;
//     token = yield call(authorize, refresh);
//     if (token == null) return;

//     yield call(delay, token.expires_in);
//   }
// }

// function* authentication() {
//   const storedToken = yield call(auth.getStoredToken);

//   while (true) {
//     if (!storedToken) yield take(SIGN_IN);

//     const { signOutAction } = yield race({
//       signOutAction: take(SIGN_OUT),
//       authLoop: call(authorizeLoop, storedToken)
//     });

//     if (signOutAction) {
//       yield call(auth.storeToken, null);
//     }
//   }
// }
