import React from 'react';
import { Jumbotron, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classes from './JumbotronMenuItem.module.scss';

// import classes from "./JumbotronMenuItem.scss";

const JumbotronMenuItem = (props) => {
  const isTvpTuw =
    window.location.hostname === 'tvp-tuwpzuw.uspartner.pl' &&
    new Date('2022-10-24').getTime() > new Date().getTime();
  const { urlPrefix, insuranceName, img, hasBorder } = props;
  return (
    <Col xs="12" lg="6">
      <Jumbotron
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/tvp/dashboard/${img}.jpg')`,
          backgroundSize: 'cover'
        }}>
        <div>
          <div>
            <Link
              to={`/${urlPrefix}/opis/`}
              style={{
                color: 'white'
              }}>
              <h1
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '1rem',
                  borderRadius: '0.25rem',
                  width: 'fit-content'
                }}>
                {insuranceName}
              </h1>
            </Link>
          </div>
          <br />
          <p className={classes.buttons}>
            <Button
              tag={Link}
              to={`/${urlPrefix}/opis`}
              color="primary"
              size="lg">
              <i className="fa fa-chevron-circle-right" /> Zobacz opis
            </Button>
            {!isTvpTuw && (
              <Button
                tag={Link}
                to={`/${urlPrefix}/kup/`}
                color="secondary"
                size="lg">
                <i className="fa fa-chevron-circle-right" /> Wypełnij deklarację
              </Button>
            )}
          </p>
        </div>
        {hasBorder ? <div className={classes.badge}>Nowy produkt</div> : null}
      </Jumbotron>
    </Col>
  );
};

export default JumbotronMenuItem;
