import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// import './App.css';
import { Route, Switch } from 'react-router-dom';

import { TvpLayout } from './containers';
// import App from './containers/TvpLayout/TvpLayout';

const AresLayout = React.lazy(() => import('./containers/AresLayout'));
// const TvpLayout = React.lazy(() => import('./containers/TvpLayout'));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

class LayoutSelector extends PureComponent {
  componentDidMount() {
    document.documentElement.classList.add('ares-tvpLayout');
    const { isAuthenticated, tvp } = this.props;

    if (isAuthenticated && !tvp) {
      document.documentElement.classList.remove(
        ...document.documentElement.classList
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated, tvp } = this.props;

    if (
      tvp !== prevProps.tvp ||
      isAuthenticated !== prevProps.isAuthenticated
    ) {
      document.documentElement.classList.add('ares-tvpLayout');

      if (isAuthenticated && !tvp) {
        document.documentElement.classList.remove(
          ...document.documentElement.classList
        );
      }
    }
  }

  render() {
    let Layout = TvpLayout;
    const { isAuthenticated, tvp } = this.props;

    if (isAuthenticated && !tvp) {
      Layout = AresLayout;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Loading...</title>
        </Helmet>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              path="/"
              name="layout"
              render={(props) => <Layout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    tvp: state.auth.user.programId === '7651f192-5247-4a85-9ed3-5e510bf96167'
  };
};

// const mapDispatchToProps = (dispatch) => {
//   // return { authCheckState: () => dispatch(authCheckState()) };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(LayoutSelector);
