import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="footer">
    <div
      className="fixedWidth footerGrid"
      // style={{
      //   display: 'grid',
      //   gridAutoFlow: 'column',
      //   gridAutoColumns: 'max-content',
      //   gridColumnGap: '1rem'
      // }}
    >
      <div className="company">
        <a
          href="https://uspartner.pl"
          target="_blank"
          rel="noopener noreferrer">
          O nas
        </a>
      </div>
      <div className="link1">
        <a
          href="/public-assets/tvp/2022/Regulamin_świadczenia_usług_drogą_elektroniczną_22.02.24.pdf"
          target="_blank"
          rel="noopener noreferrer">
          Regulamin
        </a>
      </div>
      <div className="link2">
        <Link to="/kontakt">Kontakt</Link>
      </div>
      <div className="text">
        <span className="avoidWrap">UNIQUE SERVICE PARTNER Sp. z o.o.</span>{' '}
        <span className="avoidWrap">ul. Obrzeżna 7,</span>{' '}
        <span className="avoidWrap">02-691 Warszawa.</span>{' '}
        <span className="avoidWrap">KRS: 0000634003,</span>{' '}
        <span className="avoidWrap">NIP: 525-267-38-99,</span>{' '}
        <span className="avoidWrap">REGON: 365261002,</span>{' '}
        <span className="avoidWrap">
          <a href="tel:+48224173440">Tel. 22 417 34 40</a>
        </span>{' '}
        <span className="avoidWrap">(8:00-16:00)</span>
      </div>

      <div className="logo">
        <img src="/public-assets/tvp/usp567noborder.png" alt="logo USP" />
      </div>
    </div>
  </div>
);

export default Footer;
