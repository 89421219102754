import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row, Col } from 'reactstrap';

let Kontakt = (props) => {
  return (
    <React.Fragment>
      <h2 className="page-header">Kontakt</h2>
      <Row>
        <Col xs="12" sm="6">
          <div style={{ margin: '2rem 0rem' }}>
            <h3>
              <FontAwesomeIcon icon="phone" style={{ marginRight: '1rem' }} />
              Infolinia
            </h3>
            <h3>
              <a href="tel:+48224173440">22 417 34 40</a>
            </h3>
            <span>
              Infolinia jest czynna od poniedziałku do piątku w godzinach 8:00 –
              16:00.
            </span>
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <h3>
              <FontAwesomeIcon
                icon="envelope"
                style={{ marginRight: '1rem' }}
              />
              Email
            </h3>
            <h3>
              <a href="mailto:tvp@uspartner.pl">tvp@uspartner.pl</a>
            </h3>
          </div>
        </Col>
        <Col
          xs="12"
          sm="6"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <div style={{ padding: '2rem', maxWidth: '400px' }}>
            <img
              src="/public-assets/tvp/usp567noborder.png"
              style={{ width: '100%', height: 'auto' }}
              alt="Logo"
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Kontakt;
