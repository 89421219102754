import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import { setAuthRedirectPath } from '../../store/actions';

import classes from './Login.module.scss';

import {
  Row,
  Col,
  // Alert,
  Input,
  FormGroup,
  Label,
  // FormFeedback,
  Button
} from 'reactstrap';

class Activation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: ''
    };
  }

  updateToken = (event) => {
    this.setState({
      token: event.target.value || ''
    });
  };
  render() {
    const props = this.props;

    if (props.isAuthenticated) {
      return <Redirect to={props.authRedirectPath} />;
    } else {
      const { token } = this.state;
      const tokenIsEmpty = token === '' || !token;
      return (
        <React.Fragment>
          <Row>
            <Col xs="12" md="8" lg="6" xl="5" className={classes.centered}>
              <Row>
                <Col xs="12">
                  <div className={classes.form}>
                    <h4>Aktywacja Konta</h4>
                    <FormGroup>
                      <Label for="activation">Token z wiadomości email:</Label>
                      <Input
                        placeholder="Wklej token z wiadomości email"
                        type="textarea"
                        className="form-control"
                        value={token}
                        onChange={this.updateToken}
                        rows={13}
                      />
                    </FormGroup>
                    {tokenIsEmpty && (
                      <Button
                        color="success"
                        className={classes.submitButton}
                        disabled={true}>
                        Aktywuj konto
                      </Button>
                    )}
                    {!tokenIsEmpty && (
                      <a
                        className={`btn btn-success ${classes.submitButton}`}
                        role="button"
                        href={`/api/confirm-email?${token}`}>
                        Aktywuj konto
                      </a>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Activation);
